import baseURL from "@/utils/config";
import request from "@/plugins/axios"
//获取所有明细
export function getCheckList(data,langEnv=2){
  return request({
      url:`${baseURL.api}/authapi/user/findRechargeRecord`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}
//获取用户余额
export function getBalance(langEnv=2){
  return request({
      url:`${baseURL.api}/authapi/user/findRecharge`,
      method: "post",
      headers:{
        appLangEnv:langEnv
      }
  });
}
//按照月份分类
export function getCheckDetial(data,langEnv=2){
  return request({
      url:`${baseURL.api}/authapi/user/findRechargeRecord2`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}

export function getUserInfo(data,langEnv=2){
  return request({
      url:`${baseURL.api}/authapi/user/userinfo2`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}
